.top-row ul h2 {
  color: #fff;
}

.top-row ul a {
  color: #fff !important;
}

.store-btn a,
.ussd {
  width: 70px;
}

.qr-code {
  width: 8rem;
}

.qr-code img {
  width: 100%;
  object-fit: contain;
}

.scan {
  width: 22ch;
}

.copyright {
  border-top: 1px solid #5A6064;
}

@media (min-width: 768px) {

  .store-btn a,
  .ussd {
    width: 80px;
  }
}