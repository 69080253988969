.store__btn {
    width: 250px;
    height: auto;
}

#appstore-btn,
#playstore-btn,
#playstore-btn {
    width: 100%;
    object-fit: contain;
    transition: transform 200ms ease-in-out;
}

#playstore-btn {
    transform: scale(1.1);
}

/* sm */
@media (min-width: 640px) {
    .store__btn {
        width: 380px;
        height: auto;
    }
}