.header-text {
  display: none;
}

.genericHeader {
  display: none !important;
}

.clipBG {
  width: 100vw;
  height: 60vh;
  position: relative;
  top: -40px;
}

.headerContainer {
  width: 100vw;
  padding: 0 150px;
}

.bg {
  animation: slide 15s ease-in-out infinite alternate;
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: absolute;
  right: -50%;
  top: 0;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 10%, 0 100%);
  overflow: hidden;
  height: 60vh;
}

.bg_default {
  background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
}

.bg_silver {
  background-image: linear-gradient(
    -60deg,
    rgb(77, 25, 109) 50%,
    rgb(41, 2, 53) 50%
  );
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 15s;
}

.bg3 {
  animation-duration: 15s;
}

.headerSlider {
  position: relative;
  top: -100px;
  width: 100vw;
  height: 60vh;
  clip-path: polygon(0 0, 100% 0, 100% 57%, 0 100%);
}

.smallerClipPath {
  /* display: none; */

  margin-top: 50vh;
  clip-path: polygon(0 67%, 100% 11%, 100% 33%, 0 93%);
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
