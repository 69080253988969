.background-animate {
  background-size: 400%;
  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

@keyframes AnimationName {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

/* .intercityblob {
    -webkit-mask-image: url(intercityblob.svg);
    -webkit-mask-repeat: no-repeat;
  }

  .trotroblob {
    -webkit-mask-image: url(trotroblob.svg);
    -webkit-mask-repeat: no-repeat;
  }

  .intrablob {
    -webkit-mask-image: url(trotroblob.svg);
    -webkit-mask-repeat: no-repeat;
  }  */

.animate-spin-slower {
  animation: spin 6s linear infinite;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* height: 109vh; */
  padding-top: 10%;
  padding-bottom: 4%;
}

.floating-tab {
  /* max-height: 100px; */
  min-height: 4.5rem;
  /* border: 5px solid red; */

  & .floating-tab-item-wrapper {
    width: 20dvw;
    max-width: 220px;

    /* &.floating-item {
      background: #EDFAF7;
    } */
  }
}

.floating-tab svg {
  /* width: 30px; */
  width: fit-content;
  height: fit-content;
}

.floating-txt {
  font-size: 12px !important;
}

#payment-solution {
  background-color: #fafdff;
  gap: 16px;
}

[alt="All 4 tapngo cards"] {
  aspect-ratio: 3/calc(357/282);
}

#transport-fare {
  background: #F8F9FB;
}

#better-way {
  background: #12724A;
}

.stepper__container {
  position: relative;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  width: 100%;
  margin-top: 1.5rem;


  & .stepper__item {
    display: flex;
    justify-content: start;
    align-items: center;
    row-gap: 1.5rem;
    margin-left: 3rem;

    /* make first after line 50% */
    &:is(:first-child)::after {
      height: 20%;
    }

    /* number lables */
    &:is(:nth-child(1))::before {
      content: '1';
    }

    &:is(:nth-child(2))::before {
      content: '2';
    }

    &:is(:nth-child(3))::before {
      content: '3';
    }

    &:is(:nth-child(4))::before {
      content: '4';
    }

    /* make all line not the first dashed */
    &:not(:first-child) {
      margin-top: 2rem;

      &::after {
        border-color: #6DFFC2;
        border-style: dashed !important;
        z-index: -2;
      }
    }

    /* remove line for last child */
    &:is(:last-child)::after {
      display: none;
    }
  }

  /* style for number */
  & .stepper__item::before {
    /* content: '1'; */

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -1;

    background: #12724A;
    font-weight: 600;
    color: white;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    padding: 1rem;

    transform: translateX(-3rem);
  }

  /* style for line */
  & .stepper__item::after {
    content: '';

    position: absolute;
    top: 1.6rem;
    z-index: -1;

    border: 2px solid #12724A;
    height: 70%;
    border-radius: 1rem;

    transform: translateX(-2.1rem);
  }
}

#innercity__header {
  position: relative;
  height: 100dvh;
}

#innercity__header .header__img {
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: -1;

  &::before {
    content: ' ';
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.60);
  }
}

#intercity__header {
  position: relative;
  height: 100dvh;
  margin-inline: auto;
}

#partners {
  .handshake {
    border-top-right-radius: 64px;
    border-bottom-left-radius: 64px;
  }
}

.signup__driver {
  position: relative;

  background-image: url(../../assets/driver-signup.jpg);
  background-position: center;
  background-size: cover;
  height: 674px;

  & .signup__card {
    position: absolute;
    width: 411px;

    & input {
      &[name="ghana-card"] {
        padding-left: 54px;
      }

      &::placeholder {
        color: #9CABB8;
      }
    }
  }
}

.width__img {
  max-width: 568px;
}

.aspect-5\/4 {
  aspect-ratio: 5/4;
}

.aspect-16\/9 {
  aspect-ratio: 16/9;
}

/* css debuger */
/* *,
::after,
::before {
  border: 1px solid red;
} */

.content__wrapper {
  display: flex;
  padding: var(--ui-component-lg, 48px) var(--ui-component-xx-s, 16px);
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  align-self: stretch;

  &.secondary {
    background: var(--tokens-surface-secodary, #F8F9FB);
  }

  & .overheader {
    max-width: 1184px;
    padding: 0 0 40px 0;

    & h2 {
      color: var(--Tokens-Text-default, #030F1A);
      font-family: Figtree;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      /* 125% */
      text-transform: capitalize;
    }

    & p {
      align-self: stretch;
      margin: 1rem 0 0 0;
      color: var(--tokens-text-subtext, #5A6064);
      font-family: Figtree;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      max-width: 547px;
    }
  }

  &>.content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: var(--ui-component-default, 32px);
    align-self: stretch;
    margin-inline: auto;
    /* padding-inline: var(--ui-component-xx-s, 16px); */

    /* shows on tablet and below */
    & h2 {
      color: var(--tokens-text-default, #030F1A);
      font-family: Figtree;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      /* 133.333% */
    }

    & .content__txt {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* gap: var(--ui-component-md, 40px); */
      flex: 1 0 0;
      max-width: 70ch;

      &>h2 {
        display: none;

        &.persist {
          display: unset;
          color: var(--tokens-text-default, #030F1A);
          font-family: Figtree;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
        }
      }

      & p {
        margin: 0;
        color: var(--tokens-text-subtext, #5A6064);
        font-family: Figtree;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
      }
    }

    & .image {
      display: flex;
      height: 180px;
      /* max-width: 568px; */
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;

      &.h-407 {
        height: 407px !important;
      }

      &.h-570 {
        height: 570px !important;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.contain {
          object-fit: contain;
        }
      }

    }
  }
}

/* **************************************** */
/* **********  Media Queries  ************* */
/* **************************************** */

/* lg */
@media (min-width: 1024px) {

  .hero {
    justify-content: flex-end;
  }

  #intercity__header {
    max-width: 1184px;
    height: unset;

    .header__img {
      width: 512px;
      height: 512px;
      z-index: -1;

      &::before {
        display: none;
      }
    }
  }

  .content__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--ui-component-2-xl, 80px) var(--screen-width-and-margin-margin, 128px);
    justify-content: center;
    gap: var(--ui-component-md, 40px);

    &.secondary {
      background: var(--tokens-surface-secodary, #F8F9FB);
    }

    &>.content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 48px var(--ui-component-lg, 48px);
      align-self: stretch;
      flex-wrap: wrap;
      max-width: 1184px;

      &.row-reverse {
        flex-direction: row-reverse;
      }

      &>h2 {
        display: none;
      }

      & .content__txt {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* gap: var(--ui-component-md, 40px); */
        flex: 1 0 0;
        width: 568px;

        /* shows on large screens and above */
        &>h2 {
          display: block;
          align-self: stretch;
          color: var(--tokens-text-default, #030F1A);
          font-family: Figtree;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          /* 125% */
        }

        & p {
          align-self: stretch;
          margin: 0;
          color: var(--tokens-text-subtext, #5A6064);
          font-family: Figtree;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          /* 150% */
        }
      }

      & .image {
        display: flex;
        height: auto;
        max-width: 568px;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

      }
    }
  }
}

/* md */
@media (min-width: 768px) {
  /* .hero {
    padding-inline: 100px;
  } */

  .floating-tab {
    width: fit-content;
  }

  #innercity__header {
    height: 40vh;

    & .header__img {
      height: 40vh;
    }
  }
}

/* sm */
@media (min-width: 640px) {
  .hero-txt {
    top: 20%;
  }

  .floating-txt {
    font-size: 0.874rem;
  }
}

/* -sm: targets screen width of 640px and below*/
@media (max-width: 640px) {
  .hero {
    padding-top: 20%;
  }

  .floating-tab {

      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    
    .floating-tab-item-wrapper {
      width: 100%;
    }

    & .floating-item {
      &.floating-item-bg {
        background: #EDFAF7;
      }
    }
  }
}


/* -md: targets screen width of 768px and below*/
@media (max-width: 768px) {
  /* .floating-tab {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  } */

  #intercity__header {
    & .header__img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100vh;
      z-index: -1;

      &::before {
        content: ' ';
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.60);
      }
    }

    & h1,
    p {
      color: white !important;
    }
  }

  #partners {
    .handshake {
      height: 354px;
      border-top-right-radius: 32px;
      border-bottom-left-radius: 32px;
    }
  }

  .width__img {
    max-width: 443px;
  }


}