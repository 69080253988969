.show-bg {
    background-color: white;
    color: black !important;

    & svg {
        fill: black;
    }

    & a {
        color: black;
    }

    & .download-btn {
        color: rgb(255, 255, 255);

        /* &:hover {
            color: white;
        } */
    }
}

.hide-bg {
    background-color: transparent;
    color: white;
}

.hide-bg a {
    color: white;
}

.mobile__menu {
    & .active {
        font-weight: 700;
        color: rgb(5, 150, 105);
        background-color: #F7F7F7;
        border-radius: 4px;
    }

    & a {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        width: 100%;
        padding: 16px 64px;
        color: black;
    }

    & .download__btn {
        color: white;
    }
}