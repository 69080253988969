.step__marker {
    width: 32px;
    aspect-ratio: 1/1;
}

.step:hover .step-txt dd {
    visibility: visible;
    height: 100%;
}

@media (pointer: coarse) {
    .step-txt dd {
        visibility: visible;
        height: 100%;
        opacity: 1;
    }
}